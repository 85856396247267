import { template as template_60903232b8434692a79c49955a34f5ba } from "@ember/template-compiler";
const FKLabel = template_60903232b8434692a79c49955a34f5ba(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
