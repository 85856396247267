import { template as template_28de24dd2d8d4c448d522d88f8190235 } from "@ember/template-compiler";
const WelcomeHeader = template_28de24dd2d8d4c448d522d88f8190235(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
